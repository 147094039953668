@import './normalize.css'
@import './modules/_variables'
@import './modules/_global'
@import './modules/_header'
@import './modules/_nav'
@import './modules/_about'
@import './modules/_portfolio'
@import './modules/_contact'
@import './modules/_footer'
@import './modules/_scrollButton'

h1
  color: red

@media screen and (min-width: 600px)
  body
    font-size: 1em
  h1
    color: pink

body
  color: $terciary
