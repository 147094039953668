section.flex
  justify-content: flex-start

.contact-wrap
  background-color: $terciary
  color: $primary
  font-size: initial
  form
    display: flex
    flex-direction: column
    justify-content: space-around
    height: 500px
    .input-cont
      position: relative
      input, textarea
        height: 44px
        font-size: 1.6rem
        appearance: none
        outline: none
        color: $primary
        width: 300px
        border: 1px solid rgb(84, 84, 84)
        border-radius: 4px
        background-color: transparent
        padding-left: 40px
        padding-right: 25px
        &:invalid ~ label
          top: calc( 50% - 9px )
        &:focus , &:valid
          border-color: $highlightDark
        &:focus ~ label, &:valid ~ label
          color: $highlightDark
          font-size: 1.4rem
          top: -8px
        &:focus ~ .icon-cont, &:valid ~ .icon-cont
          .icon
            fill: $highlightDark
            width: 22px
        &:valid:focus ~ .clear
          svg
            opacity: 1
      textarea
        height: initial
        resize: none
        padding-top: 2rem
        &:invalid ~ label
          top: 9px
        &:focus ~ label, &:valid ~ label
          top: -8px
        & + .icon-cont
          top: 0%
          transform: translateY(25%)
        & ~ .clear
          top: 0
          transform: translateY(50%)
      label
        color: #636465
        pointer-events: none
        background-color: $terciary
        position: absolute
        left: 36px
        transition-property: top, font-size
        transition-duration: 200ms
        transition-timing-function: ease-in
      .icon-cont
        position: absolute
        pointer-events: none
        left: 8px
        top: 50%
        transform: translateY(-50%)
        .icon
          fill: grey
          transition-property: width, fill
          transition-duration: 200ms
          transition-timing-function: ease-in
      .clear
        fill: darkred
        position: absolute
        right: 2%
        top: 50%
        transform: translateY(-50%)
        svg
          opacity: 0
          width: 20px
          cursor: pointer
          transition-property: width, opacity, fill
          transition-duration: 200ms
          transition-timing-function: ease-in
        &:hover
          svg
            width: 22px
            fill: lighten(darkred, 10%)
    .submit-button
      color: $terciary
      outline: none
      border: none
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      border-radius: 10px
      background-color: darken($highlight, 15% )
      width: 80px
      height: 40px
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out
      &:hover
        background-color: $highlight
        color: $primary
