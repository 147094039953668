/** Primary Theme **/

// $primary: #222629
// $secondary: #3d3d3d
// // $secondary: #474b4f
// $terciary: #FFF
// $offWhite: #f5f5f5
// // $highlight: #e7a007
// $highlight: #86c232

// $highlight2: #04c2c9
// $dark: #333
// $blue: #4b93b1

/** Alternate Theme **/
// $primary: #252934
// $secondary: #1B242F
// $terciary: #FFF
// $offWhite: #f5f5f5
// // $highlight: #e7a007
// $highlight: #e31b6d

// $highlight2: #04c2c9
// $dark: #333
// $blue: #4b93b1

 /** Alternate Theme 2**/
$primary: #17252A
$secondary: #1B242F
$terciary: #FFF
$offWhite: #f5f5f5
// $highlight: #e7a007
$highlight: #3aafa9
$highlight3: #90eee9

$highlight2: #04c2c9
$dark: #333
$blue: #4b93b1
$highlightDark: darken($highlight, 15% )
