.portfolio-wrap
  background-color: $offWhite
  color: $primary
  font-size: initial
  .project-list
    display: flex
    flex-wrap: wrap
    flex-direction: row
    justify-content: space-between
    list-style: none
    padding: 0
    max-width: 420px
    .project
      position: relative
      border: 1px solid rgb(119, 121, 123)
      border-radius: 5px
      background-color: $terciary
      width: 100%
      margin-bottom: 50px
      box-shadow: 0 2px 2px 0px rgba(0,0,0,.14), 0 3px 1px -2px rgba(0, 0, 0, .12)
      overflow: hidden
      transition: box-shadow 200ms ease-in-out
      &:hover
        box-shadow: 0 3px 3px 0px rgba(0,0,0,.3), 0 3px 1px -2px rgba(0, 0, 0, .12)
        & .winBar > h3
          color: $terciary
        & .desc-cont
          .bottom-desc
            color: $terciary
        & > .link-btns
          transform: translate(0 , -50%)
        & > .winBar i
          background-color: #FC5951
        & > .winBar i::before
          background-color: #E6C026
        & > .winBar i::after
          background-color: #52C22B
      .winBar
        pointer-events: none
        display: flex
        justify-content: center
        align-items: center
        position: relative
        background-color: $secondary
        width: 100%
        height: 30px
        i, i::before, i::after
          display: block
          content: " "
          background-color: #636465
          position: absolute
          border-radius: 50%
          width: 10px
          height: 10px
          transition: background 200ms ease-in-out
        i
          top: 10px
          left: 10px
          &::before
            display: inline-block
            left: 15px
          &::after
            display: inline-block
            left: 30px
        h3
          vertical-align: middle
          font-weight: 300
          margin: 0
          padding: 0
          color: #a7abaE
          transition: color 200ms ease-in-out
      video
        position: absolute
        width: 100%
        left: 0
        pointer-events: none
        z-index: 0
      .poster
        vertical-align: top
        pointer-events: none
        position: relative
        width: 100%
        opacity: 1
        z-index: 1
        // filter: grayscale(.2)  contrast(.9) url(#gamma-red)  brightness(.9)
        filter: grayscale(.3)  //contrast(.9) brightness(.9)
        transition: opacity 300ms 100ms
        &.hide
          opacity: 0
      .link-btns
        font-size: 0
        padding: 0
        list-style: none
        position: absolute
        right: 0
        top: 50%
        display: flex
        width: 40px
        height: 150px
        flex-direction: column
        justify-content: space-between
        transform: translate(100%,-50%)
        // color: #a7abaE
        transition: transform 300ms ease-in-out
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))
        z-index: 2
        .btn-wrap
          position: relative
          a
            display: inline-block
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
          &:hover > .btn
            cursor: pointer
            background-color: $highlight
          .btn
            width: 30px
            height: 30px
            background: #fff
            opacity: 0.9
            transition: background-color 150ms ease-in-out
            &.info
              mask: url(../assets/info.svg)0 0/30px 30px
            &.github
              mask: url(../assets/githubLogo.svg)0 0/30px 30px
            &.liveView
              mask: url(../assets/view.svg)0 0/30px 30px
          &:hover::before
            animation: fadeInOut 3s 100ms ease-in-out
          &::before
            pointer-events: none
            opacity: 0
            color: #fff
            display: flex
            justify-content: center
            align-items: center
            font-size: 1.3rem
            background-color: transparentize($primary,.6 )
            border: 1px solid #fff
            border-radius: 5px
            position: absolute
            width: 100px
            height: 30px
            transform: translate(-100%, -70%)
            content: attr(data-tooltip)
      .show
        animation: showAndHide 4s ease-in-out
      .desc-cont
        position: relative
        .pop-desc
          font-size: 1.2rem
          font-weight: 300
          line-height: 2
          position: absolute
          top: 0
          left: 0
          width: 100%
          margin: 0
          padding: 5px 8px 5px 8px
          background-color: rgba(0, 0, 0, .7)
          pointer-events: none
          color: #fff
          transform: translateY(0%)
          transition: transform 300ms ease-in-out
        .bottom-desc
          background-color: $secondary
          font-size: 1.4rem
          line-height: 1.4
          font-weight: 300
          position: relative
          margin: 0px
          padding: 8px 9px
          color: #a7abaE
          border-top: 1px solid $primary
          border-radius: 0 0 3px 3px
          transition: color 200ms ease-in-out

@media screen and ( min-width: 767px )
  .portfolio-wrap
    .project-list
      max-width: 2700px
      .project
        max-width: calc( 50% - 15px )

@keyframes fadeInOut
  0%
    opacity: 0
  25%
    opacity: 1
  75%
    opacity: 1
  100%
    opacity: 0
@keyframes showAndHide
  0%
    transform: translateY(0)
  15%
    transform: translateY(-100%)
  85%
    transform: translateY(-100%)
  100%
    transform: translateY(0)
