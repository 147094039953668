nav
  box-sizing: content-box
  position: sticky
  height: 60px
  width: 100%
  top: 0
  background-color: $primary
  box-shadow: 0 2px 2px 0px rgba(0,0,0,.14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0px rgba(0, 0, 0, 0.2)
  text-align: left
  border-top: 1px solid $highlight
  z-index: 100
  transition: border-color 300ms ease-in-out
  // &[stuck]
  //   border-color: transparent
  .rpLogo
    position: absolute
    top: 50%
    width: 40px
    height: 40px
    border-radius: 5px
    padding: 3px
    border: 1px solid #90eee9
    transform: translate(calc(-45vw + 50%),-50%)
    &:hover
      cursor: pointer
  .link-wrap
    position: absolute
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    top: 50px
    width: 100%
    height: 0
    overflow: hidden
    background: transparentize($secondary, 0.05)
    transition: height 300ms ease-in-out
    .link
      opacity: 0
      text-align: center
      display: block
      font-size: 1.6rem
      padding: 12px 20px
      user-select: none
      text-transform: uppercase
      cursor: pointer
      transform: translateX(-20px)
      transition: transform .5s ease-in-out, opacity .5s ease-in,color 250ms ease-in-out 0ms
      // Set varing delays for each Link
      @for $i from 1 through 5
        &:nth-child(#{$i})
          transition-delay: calc((#{$i} - 1) * 30ms)
      &:hover
        transition: color 100ms
        color: lighten($highlight, 10)
    &.is-visible
      height: 180px
      box-shadow: 0 2px 2px 0px rgba(0,0,0,.14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0px rgba(0, 0, 0, 0.2)
      .link
        opacity: 1
        transform: translateX(0)
    .link--selected
      color: $highlight
  .ham-container
    position: absolute
    top: 50%
    right: 5vw
    height: 50px
    width: 50px
    transform: translateY(-50%)
    &:hover
      cursor: pointer
      .ham, .ham::before, .ham::after
        background-color: $highlight
  .ham, .ham::before, .ham::after
    background-color: $terciary
    border-radius: 1px
    width: 28px
    height: 2px
    transition-duration: 300ms
    transition-timing-function: ease-in-out
  .ham
    margin: 0 auto
    margin-top: calc( 50% - 1px)
    &::before, &::after
      content: ''
      position: absolute
      transform-origin: right
      left: calc( 50% - 14px)
    &::before
      top: 33%
    &::after
      bottom: 33%
  .is-open
    .ham
      width: 1px
      &::before
        transform: translate(-4px, -2.4px) rotate(-45deg)
      &::after
        transform: translate(-4px, 2.4px) rotate(45deg)
@media screen and (min-width: 600px)
  nav
    width: 100%
    transform: translateX(0)
    .link-wrap
      position: initial
      flex-direction: initial
      justify-content: initial
      height: initial
      padding-left: 120px
      background: none
      .link
        display: inline
        opacity: 1
    .ham-container
      display: none
@media screen and (min-width: 900px)
  nav
    .rpLogo
      transform: translate(calc(-50vw + 50%), -50%)
