.about-wrap
  background-color: $terciary
  color: $primary
  font-size: initial
  // margin-bottom: 60px
  p
    font-size: 1.6rem
    text-align: justify
    max-width: 800px
    line-height: 3rem
  .tech-icons
    padding: 20px
    width: 90%
    display: flex
    justify-content: space-evenly
    flex-wrap: wrap
    .logo-cont
      display: flex
      flex-direction: column
      align-items: center
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, .3))
      &:hover
        filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, .2))
        & > .web-logo
          background-color: lighten($highlight, 8% )
          transform: scale(1.1)
      .web-logo
        position: relative
        $size: 0 0 / 80px 80px no-repeat
        background-color: $highlight
        background-image: linear-gradient(130deg,rgba(0,0,0,0) 20%, darken($highlight, 8%)120%)
        width: 80px
        height: 80px
        margin: 10px 10px 10px 10px
        transition: transform 300ms ease-in-out, background-color 300ms ease-in-out
        &.html
          mask: url(../assets/html5Logo.svg) $size
        &.css
          mask: url(../assets/css3Logo.svg) $size
        &.js
          mask: url(../assets/jsLogo.svg) $size
        &.ts
          mask: url(../assets/tsLogo.svg) $size
        &.react
          mask: url(../assets/reactLogo.svg) $size
        &.sass
          mask: url(../assets/sassLogo.svg) $size
        &.git
          mask: url(../assets/gitLogo.svg) $size
        &.npm
          mask: url(../assets/npmLogo.svg) $size
        &.d3
          mask: url(../assets/d3Logo.svg) $size
      .web-desc
        color: $primary
        padding: 0
        margin-top: 0
@media screen and (min-width: 600px)
  .web-logo
    margin: 10px 50px 10px 50px !important
