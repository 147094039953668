.home-wrap
  height: 100vh
  background-color: $primary
  background-image: linear-gradient(180deg, #000000 -50%, $primary 100%)
  .custom-cursor
    pointer-events: none
    position: absolute
    top: -25px
    left: -25px
    width: 50px
    height: 50px
    border-radius: 50%
    background-color: lighten($highlight, 10 )
    mix-blend-mode: difference
    z-index: 10
  & .mobile
    display: none

section
  position: relative
  .text-container
    display: flex
    flex-direction: column
    justify-content: center
    border-radius: 3rem
    height: 15rem
    z-index: 1
  .text
    display: inline-block
    font-size: 2.1rem
    font-weight: 500
    z-index: 1
    span
      display: inline-block
    .sentence-top
      display: flex
      justify-content: center
      animation: reposition 3s ease-out forwards
      .greeting, .sentence
        display: inline-block
      .greeting
        animation: intro 2s ease-out
      .sentence
        overflow: hidden
        span:first-of-type
          transform: translateX(-100%)
          animation: expand 2.5s ease-out 1.5s forwards
    .sentence-bottom
      display: inline-block
      overflow: hidden
      span
        transform: translateX(-100%)
        animation: expand 2.5s ease-out 3s forwards
  .access-button
    opacity: 0
    width: 50px
    margin-top: 12rem
    transform: rotate(180deg)
    animation: intro 3s ease-out 4s forwards
    svg
      stroke: #fff
      .first, .second
        animation: advance 1s  infinite alternate
      .second
        animation-delay: 400ms
    &:hover
      cursor: pointer
      .first, .second
        transition: stroke 300ms ease-in-out
        animation: step-end
        stroke: $highlight

@keyframes advance
  from
    stroke: lighten($highlight, 10 )
  to
    stroke: #fff

@keyframes reposition
  0%
    transform: translateX(30%)
  50%
    transform: translateX(30%)
  100%
    transform: translateX(0)

@keyframes expand
  0%
    transform: translateX(-100%)
  50%
    transform: translateX(-100%)
  100%
    transform: translateX(0%)

@keyframes intro
  0%
    opacity: 0
  20%
    opacity: 0
  100%
    opacity: 1
@keyframes canvas-intro
  0%
    opacity: 0
  20%
    opacity: 0.1
  90%
    opacity: 0.3
  100%
    opacity: .9

@media screen and (min-width: 600px)
  section .text
    font-size: 3.6rem
    line-height: 30pt
    font-weight: 300

.highlight
  color: $highlight

canvas
  pointer-events: none
  width: 100%
  height: 100%
  position: absolute
  top: 0
  z-index: 0
  animation: canvas-intro 6s ease-out forwards
