.scroll-button
  visibility: hidden
  opacity: 0
  position: fixed
  box-shadow: 0 4px 5px 0px rgba(0,0,0,.14), 0 1px 10px 0px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)
  bottom: 5vw
  right: 5vw
  width: 50px
  height: 50px
  background-color: darken($highlight,10 )
  border-radius: 50%
  cursor: pointer
  z-index: 100
  transition: visibility 0s 350ms , opacity  300ms ease-in-out
  &:hover
    background-color: $highlight
  svg
    height: 100%
    width: 100%
    stroke: $terciary
  &.isShowing
    visibility: visible
    opacity: 0.9
    transition: visibility 0s, opacity  300ms ease-in-out

@media screen and (min-width: 1100px)
  .scroll-button
    right: calc(50% - 500px)
